import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuBar from '../components/home/MenuBar';
import LaRubriqueHeader from '../components/LaRubrique/LaRubriqueHeader';
import LaRubriqueListContent from '../components/LaRubrique/LaRubriqueListContent';
import LaRubriqueFooter from '../components/LaRubrique/LaRubriqueFooter';
import FooterLightVersion from '../components/home/v3/FooterLightVersion';
import Layout from '../layouts/index';
import { Container, Content as StyledContent } from '../components/home/homeStyledComponents';
import { mobileThresholdPixels } from '../components/home/v3/styledComponents';

const Content = styled(StyledContent)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 22px;
    box-sizing: border-box;
  }
`;

const ClothesListTemplate = ({
  pageContext: {
    subCategoryItem, subCategory, category, categories, posts,
  },
}) => (
  <Layout
    routeSlug={subCategoryItem?.slug ? `LaRubriqueCategory_${subCategoryItem.slug}` : 'LaRubrique'}
  >
    <Container>
      <MenuBar />
      <Content>
        <LaRubriqueHeader category={category} categories={categories} />
        <LaRubriqueListContent posts={posts} category={category} subCategory={subCategory} subCategoryItem={subCategoryItem} />
      </Content>
      <LaRubriqueFooter category={category} preFilledSteps={subCategoryItem?.preFilledSteps} />
      <FooterLightVersion />
    </Container>
  </Layout>
);

ClothesListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    subCategory: PropTypes.shape({}),
    subCategoryItem: PropTypes.shape({
      slug: PropTypes.string,
      preFilledSteps: PropTypes.shape({}),
    }),
    category: PropTypes.shape({}),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ClothesListTemplate;
